import { Box, Button, ButtonGroup, Card, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { AddZero } from '../../../app/AppUtils';
import { getProgramSubprograms, putProgramSubprograms, setFailedProgramSubprograms } from './programManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function ProgramSubprogramEditCard({ handleClose }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedProgram, selectedProgramSubprogram, putProgramSubprogramsStatus, showProgramSubprogramFailed } = useSelector((state) => state.management.program);

    // Put status callback
    useEffect(() => {
        if (putProgramSubprogramsStatus === "success") {
            dispatch(setFailedProgramSubprograms(false));
            dispatch(getProgramSubprograms(selectedProgram.id));
            handleClose();
        } else if (putProgramSubprogramsStatus === "failed") {
            dispatch(setFailedProgramSubprograms(true));
        };
    }, [dispatch, handleClose, selectedProgram, putProgramSubprogramsStatus]);

    // Property states
    const [updatedProgramSubprogram, setUpdatedProgramSubprogram] = useState({});
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState();

    // Selected program state update 
    useEffect(() => {
        if (selectedProgramSubprogram)
            setUpdatedProgramSubprogram(selectedProgramSubprogram);
    }, [selectedProgramSubprogram]);

    // Keep track of changed state 
    useEffect(() => {
        if (updatedProgramSubprogram !== selectedProgramSubprogram) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedProgramSubprogram, updatedProgramSubprogram]);

    // Check start/end date range
    useEffect(() => {
        if (Date.parse(updatedProgramSubprogram.startTime) >= Date.parse(updatedProgramSubprogram.endTime)) {
            setError(true);
        } else {
            setError(false);
        };
    }, [updatedProgramSubprogram]);

    // Update click function
    const updateClick = () => {
        if (error)
            return;

        updatedProgramSubprogram.ModifiedBy = `AP/${user.number}`;
        dispatch(putProgramSubprograms(updatedProgramSubprogram));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.LINKED} {resourceFinal[appLanguage].words.SUBPROGRAM.toLowerCase()} {resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth type="number"
                                inputProps={{ min: 0 }}
                                defaultValue={siomSettings?.flagProgress ? (selectedProgramSubprogram?.minMoments ?? 0) : (selectedProgramSubprogram?.minPoints ?? 0)}
                                label={siomSettings?.flagProgress ? resourceFinal[appLanguage].words.MOMENTS_MIN : resourceFinal[appLanguage].words.POINTS_MIN}
                                onChange={(event) =>
                                    siomSettings?.flagProgress ?
                                        setUpdatedProgramSubprogram({ ...updatedProgramSubprogram, minMoments: event.target.value ? event.target.value : null })
                                        :
                                        setUpdatedProgramSubprogram({ ...updatedProgramSubprogram, minPoints: event.target.value ? event.target.value : null })
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth type="number"
                                inputProps={{ min: 0 }}
                                defaultValue={siomSettings?.flagProgress ? (selectedProgramSubprogram?.maxMoments ?? 0) : (selectedProgramSubprogram?.maxPoints ?? 0)}
                                label={siomSettings?.flagProgress ? resourceFinal[appLanguage].words.MOMENTS_MAX : resourceFinal[appLanguage].words.POINTS_MAX}
                                onChange={(event) =>
                                    siomSettings?.flagProgress ?
                                        setUpdatedProgramSubprogram({ ...updatedProgramSubprogram, maxMoments: event.target.value ? event.target.value : null })
                                        :
                                        setUpdatedProgramSubprogram({ ...updatedProgramSubprogram, maxPoints: event.target.value ? event.target.value : null })
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                <DateTimePicker
                                    slotProps={{ textField: { fullWidth: true } }}
                                    label={`${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`}
                                    defaultValue={dayjs(selectedProgramSubprogram?.startTime)}
                                    onChange={(value) => setUpdatedProgramSubprogram({
                                        ...updatedProgramSubprogram,
                                        startTime: value ? `${AddZero(value.$y)}-${AddZero(Number(value.$M) + 1)}-${AddZero(value.$D)}T${AddZero(value.$H)}:${AddZero(value.$m)}:${AddZero(value.$s)}` : null
                                    })} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                <DateTimePicker
                                    slotProps={{ textField: { fullWidth: true } }}
                                    label={`${resourceFinal[appLanguage].words.END}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`}
                                    defaultValue={dayjs(selectedProgramSubprogram?.endTime)}
                                    onChange={(value) => setUpdatedProgramSubprogram({
                                        ...updatedProgramSubprogram,
                                        endTime: value ? `${AddZero(value.$y)}-${AddZero(Number(value.$M) + 1)}-${AddZero(value.$D)}T${AddZero(value.$H)}:${AddZero(value.$m)}:${AddZero(value.$s)}` : null
                                    })} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth type="number"
                                inputProps={{ min: 0 }}
                                defaultValue={selectedProgramSubprogram?.maxAbsence ?? 0}
                                label={resourceFinal[appLanguage].words.ABSENCE_MAX}
                                onChange={(event) => setUpdatedProgramSubprogram({
                                    ...updatedProgramSubprogram,
                                    maxAbsence: event.target.value ? event.target.value : null
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        {siomSettings?.enableRequiredPrograms && <Grid item xs={6}>
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.REQUIRED} ${resourceFinal[appLanguage].words.SUBPROGRAM.toLowerCase()}`}
                                control={<Checkbox
                                    checked={updatedProgramSubprogram?.isRequired ? true : false}
                                    onChange={(event) => setUpdatedProgramSubprogram({
                                        ...updatedProgramSubprogram,
                                        isRequired: event.target.checked ? true : false
                                    })}
                                />}
                            />
                        </Grid>}
                        {siomSettings?.flagProgress && <Grid item xs={6}>
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.FLAGS_GREEN_MULT} ${resourceFinal[appLanguage].words.PROGRESS}`}
                                control={<Checkbox
                                    checked={updatedProgramSubprogram?.greenFlagsProgress ? true : false}
                                    onChange={(event) => setUpdatedProgramSubprogram({
                                        ...updatedProgramSubprogram,
                                        greenFlagsProgress: event.target.checked ? true : false
                                    })}
                                />}
                            />
                        </Grid>}
                        <Grid item xs={12}>
                            {error && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROGRAM_MANAGEMENT_TIME}</Typography>}
                            {showProgramSubprogramFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                <Button disabled={!changed || error} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}
