import { Box, Card, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { CardHeader, CardMedium } from '../../../app/AppStyles';
import FlagDisplay from '../../flags/FlagDisplay';
import ProgressActivities from '../../progress/ProgressActivities';
import ProgressBar from '../../progress/ProgressBar';
import ProgressProgramDisplay from '../../progress/ProgressProgramDisplay';
import ProgressRemarks from '../../progress/ProgressRemarks';

export default function SearchStudentProgressTab({ studentId }) {
    const { activeThemeSettings } = useSelector((state) => state.settings.theme);
    const { searchStudent } = useSelector((state) => state.search);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <Grid container spacing={1} item xs={12}>
            {searchStudent?.studentProgress &&
                <Grid item xs={12} md={activeThemeSettings.flagsEnabled ? 8 : 12} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 1 auto', }}>
                            <Box sx={{ flex: '1 1 auto' }}>
                                <ProgressBar progressPoints={searchStudent?.studentProgress?.totalPoints} legendDisplay={true} barHeight={180} />
                            </Box>
                        </Box>
                    </Card>
                </Grid>}
            {(activeThemeSettings.flagsEnabled && searchStudent?.studentProgress) &&
                <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <FlagDisplay progressFlags={searchStudent?.studentProgress?.progressFlags} requiredPoints={searchStudent?.studentProgress?.requiredPoints} xsIconSize={60} mdIconSize={60} compact={false} />
                    </Card>
                </Grid>}
            {searchStudent?.studentProgress &&
                <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <Typography variant="h6" sx={{ ...CardHeader }}>{resourceFinal[appLanguage].messages.CURRENT_CLINICS}</Typography>
                        <ProgressActivities progressActivities={searchStudent?.studentProgress?.progressActivities} employeeMode employeeStudentId={studentId} />
                    </Card>
                </Grid>}
            {searchStudent?.studentProgress?.progressRemarks.remarksCount > 0 &&
                <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <Typography variant="h6" sx={{ ...CardHeader }}>{resourceFinal[appLanguage].words.REMARKS} ({searchStudent?.studentProgress?.progressRemarks.remarksCount})</Typography>
                        <ProgressRemarks remarks={searchStudent?.studentProgress?.progressRemarks.remarks} />
                    </Card>
                </Grid>}
            {searchStudent?.studentProgress &&
                <Grid item xs={12}>
                    <ProgressProgramDisplay progressProgram={searchStudent?.studentProgress?.progressProgram} />
                </Grid>}
            {!searchStudent?.studentProgress &&
                <Grid item xs={12}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <Typography variant="body1" textAlign="center">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_CONTENT}</Typography>
                        <Typography variant="body1" textAlign="center">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_CONTENT_REASON}</Typography>
                    </Card>
                </Grid>}
        </Grid>
    );
}
